import React, { Component } from "react";
import ChatBot from "react-simple-chatbot";

import { ThemeProvider } from "styled-components";

import chat_styles from "./config/chat_style";

import botAvatar from "../img/BaxterBot.jpg";

const steps = [
  {
    id: "1",
    message:
      "Arrrooou!! Stranger! Welcome to my Hooman's website. I am Doggo Bot. What can I do for you?",
    trigger: "echo"
  },
  {
    id: "echo",
    user: true,
    trigger: "echo-bot"
  },
  {
    id: "echo-bot",
    message: "{previousValue}",
    trigger: "echo"
  }
];

class WebChatBot extends Component {
  render() {
    return (
      <ThemeProvider theme={chat_styles}>
        <ChatBot
          steps={steps}
          botAvatar={botAvatar}
          recognitionEnable={true}
          headerTitle=""
          floating="true"
          floatingStyle={{
            backgroundPosition: "center"
          }}
          style={{
            backgroundSize: "100%",
            backgroundRepeat: "repeat",
            backgroundColor: "#848484"
          }}
          avatarStyle={{ width: "auto", overflow: "auto" }}
          bubbleStyle={{ fontSize: "15px" }}
        />
      </ThemeProvider>
    );
  }
}

export default WebChatBot;
