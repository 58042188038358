import React, { Component } from "react";
import {
  BrowserRouter as Router,
  Route,
  Link,
  Redirect
} from "react-router-dom";

import AboutMe from "./aboutMe";
import Resume from "./Resume";
import ContactInfo from "./ContactInfo";
import Projects from "./Projects";

class NavBar extends Component {
  state = {
    toggle: false
  };

  render() {
    return (
      <Router>
        <div className="container-fluid">
          <nav className="navbar navbar-inverse navbar-dark bg-info">
            <font className="navbar-brand">Menu</font>
            <button
              className="navbar-toggler collapsed"
              type="button"
              data-toggle="collapse"
              data-target="#NavBarItems"
              aria-controls="NavBarItems"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon" />
            </button>
          </nav>
          <div className="navbar-collapse collapse" id="NavBarItems">
            <div className="bg-dark p-4">
              <font className="text-white h4">Pick Your Poison</font>
              <nav className="nav flex-column">
                <Link
                  className="nav-link font-weight-bold aling-right"
                  to="/AboutMe"
                >
                  About Me
                </Link>
                <Link className="nav-link font-weight-bold active" to="/Resume">
                  Resume
                </Link>
                <Link className="nav-link font-weight-bold" to="/Projects">
                  Projects
                </Link>
                <Link className="nav-link font-weight-bold" to="/ContactInfo">
                  Contact Information
                </Link>
                <Link className="nav-link font-weight-bold" to="/Pictures">
                  Pictures
                </Link>
              </nav>
            </div>
          </div>
          <Redirect from="/" exact to="/AboutMe" />
          <Route path="/AboutMe" component={AboutMe} />
          <Route path="/Resume" component={Resume} />
          <Route path="/Projects" component={Projects} />
          <Route path="/ContactInfo" component={ContactInfo} />
        </div>
      </Router>
    );
  }
}

export default NavBar;

/*<div clas="pos-f-t">
          <nav class="navbar navbar-dark bg-info">
            <a class="navbar-brand" />
            <button
              class="navbar-toggler collapsed"
              type="button"
              data-toggle="collapse"
              data-target="#NavBarItems"
              aria-controls="NavBarItems"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span class="navbar-toggler-icon" />
            </button>
          </nav>
          
          <div class="navbar-collapse collapse" id="NavBarItems">
            <div class="bg-dark p4">
              <h5 class="text-white h4">Pick Your Poison</h5>
            </div>
          </div>
        </div>*/
