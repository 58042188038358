import React, { Component } from "react";

import myPic from "../img/me.JPG";

class aboutMe extends Component {
  render() {
    return (
      <div className="container">
        <div className="row">
          <div className="col bg-primary shadow aboutMeTitle">
            <div className="text-dark font-weight-bold text-center aboutMeText">
              About Me
            </div>
          </div>
        </div>
        <div className="row shadow-lg grid-container">
          <div className="col">
            <img className="picOfMe" src={myPic} alt="myPicture" />
          </div>
          <div className="col-*-*">
            <section className="aboutMeSection">
              <article>
                Hello, stranger, I am an educated and passionate graduate from
                The University of Texas at San Antonio with a Computer science
                major concentrated in Software Engineering and Data Science.
                Currently, I am an active learner, and I work for Nexus Edge Inc
                as a Software Engineer. My personal areas of interest are Data
                Science and Software Engineering. I enjoy going through the
                process of Software Engineering SRS to deploy a successful
                operational interface.
              </article>
              <br />
              <article>
                Analytical Software Engineer motivated to achieve customer needs
                by analyzing business requirements, including a focus on
                understanding end user workflow. Ability to work well besides
                end users and execute complex tasks. Self-starter with the
                ability to work with little oversight.
              </article>
            </section>
          </div>
        </div>
      </div>
    );
  }
}

export default aboutMe;
