import React, { Component } from "react";
import headerPic from "../img/robot2.jpg";

class Header extends Component {
  render() {
    return (
      <div className="container-fluid headerContainer">
        <header>
          <picture>
            <img className="headerPic" src={headerPic} alt="HeaderPic" />
          </picture>
          <div className="centeredText">Welcome to Eduardo Mata's Network!</div>
        </header>
      </div>
    );
  }
}

export default Header;
