const chat_styles = {
  fontFamily: "Nunito Sans",
  headerBgColor: "#bcbcbc",
  headerFontColor: "#fff",
  headerFontFamily: "Montseratt",
  botBubbleColor: "#c1c1c1",
  botFontColor: "#110c0c",
  userBubbleColor: "#e8e8e8",
  userFontColor: "#110c0c"
};

export default chat_styles;
