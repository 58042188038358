import React, { Component } from "react";

import NavBar from "./components/Navbar";
import WebChatBot from "./components/chatbot";
import Header from "./components/Header";

class App extends Component {
  render() {
    return (
      <React.Fragment>
        <Header />
        <NavBar />

        <div className="container fixed-bottom position-static">
          <div className="row m-2 justify-content-centerr">
            <div className="col-md-auto">
              <WebChatBot />
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default App;
