import React, { Component } from "react";

import gmbsales from "../img/gmsalesarch.png";

class Projects extends Component {
  render() {
    return (
      <div className="container">
        <br />
        <div className="row">
          <div className="col">
            <div className="thumbnail rel-pos">
              <a
                target="_blank"
                href="https://gmbarchsales.com"
                rel="noopener noreferrer"
              >
                <img src={gmbsales} alt="gmbsales" className="imgThumbnail" />
                <p>gmb arch sales</p>
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Projects;
