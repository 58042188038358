import React, { Component } from "react";

class JobDesc extends Component {
  constructor(props) {
    super(props);
    this.jobDesc = this.props.desc.map((jobItem, index) => (
      <li key={index}>{jobItem}</li>
    ));
    this.state = {
      mValue: 0
    };
  }

  componentDidMount() {
    this.handleLeftMargin(this.props.employer, window.screen.width);
  }

  componentWillUnmount() {
    this.setState({
      mValue: 0
    });
    this.handleLeftMargin(this.props.employer, window.screen.width);
  }
  handleLeftMargin = (emp, screenWidth) => {
    if (emp.length <= 15) {
      if (screenWidth <= 375) {
        this.setState({
          mValue: -8
        });
      } else if (screenWidth === 768) {
        this.setState({
          mValue: 0
        });
      } else if (screenWidth >= 769) {
        this.setState({
          mValue: 129
        });
      }
    } else if (emp.length > 16) {
      if (screenWidth >= 769) {
        this.setState({
          mValue: 56
        });
      } else if (screenWidth <= 375) {
        this.setState({
          mValue: -1
        });
      }
    }
  };

  render() {
    return (
      <React.Fragment>
        <div className="row jobRow">
          <div className="col">
            <span className="text-left pdl">{this.props.date}</span>
          </div>
          <div className="col">
            <span className="font-weight-bold pdlm">{this.props.title}</span>
          </div>
          <div className="col">
            <span
              className="text-right text-monospance pdlmr"
              style={{ marginLeft: this.state.mValue }}
            >
              {this.props.employer}
            </span>
          </div>{" "}
          <br />
        </div>
        <div className="row jobRow">
          <div className="desListCol">
            <ul>{this.jobDesc}</ul>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default JobDesc;
