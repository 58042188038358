import React, { Component } from "react";

import ProgressBar from "./ProgressBar";
import JobDesc from "./JobDesc";

class Resume extends Component {
  constructor(props) {
    super(props);
    this.jobsList = {
      jobs: [
        {
          id: 0,
          title: "Software Engineer",
          date: "Sept. 2018 - Present",
          employer: "Nexus Edge Inc.",
          jobDesc: [
            "Built a messaging RESTful API and serialized the necessary objects to communicate with Nexus Edge NLP API.",
            "Automated the creation of databases for several products that Nexus Edge currently manages and currently do database administration.",
            "Improved the routines executed by Nexus Edge proprietary A.I. by 73% after doing benchmarks with the legacy production A.I routines."
          ]
        },
        {
          id: 1,
          title: "Project Assistant",
          date: "March 2018 - Aug. 2016",
          employer: "UTSA Campus Recreation",
          jobDesc: [
            "Developed clever scopes and implemented project plans to increase the number of projects per week to secure usage of the facilities",
            "Achieved project engineering and project management optimizing the number of tasks done by the staff 17% per week."
          ]
        }
      ]
    };
  }
  render() {
    return (
      <div className="container">
        <div className="row firstRow">
          <div className="skillsCol">
            <h2>Technical Skills</h2>
            <span>Python, C, Java</span>
            <ProgressBar level={92} comp={"Extensive"} color="bg-success" />
            <br />
            <span>C++, Perl, SQL</span>
            <ProgressBar level={80} comp={"Procient"} color="bg-info" />
            <br />
            <span>JavaScript, awk, sed</span>
            <ProgressBar level={75} comp={"Competent"} color="bg-warning" />
            <br />
            <span>HTML, CSS, ReactJs</span>
            <ProgressBar level={65} comp={"Knowledgeable"} color="bg-danger" />
          </div>
          <div className="col edCol">
            <h2>Education</h2>
            <em>B.S., Computer Science</em> <br />
            <span>Specialization: Software Engineer</span> <br />
            <span>The University of Texas at San Antonio</span>
            <br />
            <span>2018 | San Antonio, Texas</span>
            <br />
            <h6 className="font-weight-bold" style={{ marginTop: 10 }}>
              Relevant Coursework
            </h6>
            <ul className="circle">
              <li>Software Engineer</li>
              <li>Database Systems</li>
              <li>OOP</li>
              <li>Applications Programming</li>
              <li>Data Minning</li>
            </ul>
          </div>
        </div>
        <div className="row expRow">
          <h2 className="experience">Experience</h2>
          <br />
        </div>
        {this.jobsList.jobs.map((job, index) => (
          <JobDesc
            id={job.id}
            key={job.id.toString()}
            title={job.title}
            date={job.date}
            employer={job.employer}
            desc={job.jobDesc}
          />
        ))}
      </div>
    );
  }
}

export default Resume;
