import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import registerServiceWorker from "./registerServiceWorker";

import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap";

import "./css/header.css";
import "./css/resume.css";
import "./css/projects.css";
import "./css/aboutMe.css";

ReactDOM.render(<App />, document.getElementById("root"));

//var elem = document.querySelector("svg");
//ReactDOM.render(<App />, elem.parentNode.removeChild(elem));

registerServiceWorker();
