import React, { Component } from "react";

class ProgressBar extends Component {
  constructor(props) {
    super(props);
    this.level = this.props.level.toString() + "%";
    this.comp = this.props.comp;
    this.colorBar = this.props.color;
    this.state = {
      fontColor: ""
    };
  }
  componentDidMount() {
    this.changeFontColor();
  }

  changeFontColor = () => {
    if (this.colorBar === "bg-warning") {
      this.setState({ fontColor: "text-dark" });
    }
  };

  render() {
    return (
      <div className="progress">
        <div
          className={`progress-bar progress-bar-striped progress-bar-animated ${
            this.colorBar
          } ${this.state.fontColor}`}
          style={{ width: this.level }}
        >
          {this.level} {this.comp}
        </div>
      </div>
    );
  }
}

export default ProgressBar;
